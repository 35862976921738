import { Html, Head, Main, NextScript } from 'next/document';
import React from 'react';

import { initSentry } from './initSentry';

import { isMAIPasPDF } from 'businessLogic/Payment/helpers';
import { onDocumentStateComplete } from 'businessLogic/utils/performanceActions';
import SocialMeta from 'components/Core/SocialMeta/SocialMeta';
import Logger from 'reporting/Logger';
import SegmentIO from 'reporting/SegmentIO';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
const splunkReporter = SplunkReporter.getInstance();
import SplunkClientReporter from 'reporting/splunk/reporter.client';
import WavefrontReporter from 'reporting/wavefront/WavefrontReporter';
import { isAppleProduct, isSafari } from 'server/helpers/deviceInfo';
import { PaymentAmountValidator } from 'shared/PaymentHelpers';
import {
  loadGdpr,
  getEnabledPaymentMethod,
  mark,
  getInitialSelectedPaymentMethod,
  initLogger,
} from 'shared/clientUtils';
import { isOneToManyPaymentRequest, enabledPaymentMethodsForCABankPayments } from 'shared/utils';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import {
  getRiskProfileToken,
  updateShouldShowSchedulePay,
  updateShouldShowAutopay,
  updateCanCancelSchedule,
  reportPageView,
  registerPageLoadObserver,
} from 'store/insight/slice';
import {
  initializeApplePay,
  initializePayPal,
  checkIFPaypalEnabled,
  initializePPAAMPayLater,
} from 'store/payment/slice';
import { initPayorHistoryState } from 'store/payorHistory/slice';
import { getOpenInvoices } from 'store/sale/slice';
import { initializeStore } from 'store/store';

const wavefrontReporter = WavefrontReporter.getInstance();
const isServer = () => typeof window === 'undefined' || window.isServer === true;
export const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

/**
 *
 * @param {*} initialState
 * @returns {import('redux').Store}
 */
function getOrCreateStore(initialState) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer()) {
    return initializeStore(initialState);
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState);
  }
  return window[__NEXT_REDUX_STORE__];
}

const _CPStore = (Page) => {
  return class PageWithCPStore extends React.Component {
    static PAGE_MESSAGES = Page.PAGE_MESSAGES;
    constructor(props) {
      super(props);
      this.reduxStore = getOrCreateStore(props.initialReduxState);

      if (!isServer()) {
        // configure Logger and reporters
        Logger.addNamespace(new SplunkClientReporter(this.reduxStore));
        initSentry(props.initialReduxState);
        initLogger(props.initialReduxState);
        this.initializeWavefrontReporter(props);
      }
    }

    initializeWavefrontReporter(props) {
      try {
        const {
          initialReduxState: { config, auth, insight, sale, companyInfo },
        } = props;
        wavefrontReporter.init({
          ...config,
          ...auth,
          ...insight,
          ...sale,
          ...companyInfo,
        });
      } catch (e) {
        return;
      }
    }
    // eslint-disable-next-line no-unused-vars
    static async getInitialProps(appContext) {
      // fill initial state according to page
      appContext.initialState = {};
      if (isServer()) {
        // Server side, build the store for client usage
        //
        // Get the `locale` and `messages` from the request object on the server.
        // In the browser, use the same values that the server serialized.
        const {
          ctx: { req },
        } = appContext;
        /**
         * @type {{featureFlags: Record<string, boolean>}}
         */
        const {
          headers,
          model,
          paypalPayment,
          transactionType,
          sale,
          companyInfo,
          featureFlags,
          isSubscription,
          isPreview = false,
          pageName,
        } = req;

        if (sale && companyInfo) {
          appContext.initialState.sale = sale;
          appContext.initialState.companyInfo = companyInfo;
        }

        // if we're getting a model we need to extract out everything an put it in the initial state of our store
        if (model) {
          const {
            summary: {
              isFullyPaid,
              isPartiallyPaid,
              allowOverPay,
              paymentDueStatus,
              payPalProcessor,
              isPayPalCommerceInvoice,
            },
            profileResponse: { contextData: profileData },
            paymentAccountsResponse: {
              merchantId,
              depositDisabled,
              offerId,
              paymentProcessor,
              paypalMerchantId,
              debitCardEnabled,
            },
            authenticateResponse: {
              countryCode,
              regionIsoCode,
              ticket,
              realmId,
              recipientEmail,
              isUserSignedIn,
              isEntityPromoted,
              authToken,
              authLevel,
              entityId,
              syncToken,
              username,
            },
            enabledCCTypes,
            token,
            isSalesCheckoutInvoice,
            isQBDTInvoiceRollBack,
            view2pay,
            openInvoices,
            ixp,
          } = model;

          // flatten some data to make it easier to put in the initial state
          let {
            balanceAmount,
            domainId,
            companyId,
            appSourceOffering,
            freightAmount,
            discountAmount,
            shippingAmount,
            taxAmount,
            dutyAmount,
            partialPaymentEnabled,
          } = profileData;

          // L3 Logic
          if (!discountAmount) {
            discountAmount = 0;
          }

          if (shippingAmount) {
            freightAmount = shippingAmount;
          }

          if (!freightAmount) {
            freightAmount = 0;
          }

          if (!dutyAmount) {
            dutyAmount = 0;
          }

          if (!taxAmount) {
            taxAmount = 0;
          }

          // @todo Server tests are breaking if we don't do it like that since in a lot of them there is no discovery object on the request.
          const paypalCommerce =
            req.discovery &&
            req.discovery.externalPaymentMethods &&
            req.discovery.externalPaymentMethods.paypalCommerce;

          appContext.initialState.insight = {
            offeringId: req.discovery && req.discovery.offeringId,
            merchantId,
            isPartiallyPaid,
            isFullyPaid,
            domainId,
            appSourceOffering,
            token,
            offerId,
            view2pay,
            paymentProcessor,
            debitCardEnabled,
            companyId,
            riskProfileToken: null,
            isPayable: req.isPayable,
            isPayEnabled: req.isPayEnabled,
            shouldShowSchedulePay: false,
            shouldShowAutopay: false,
            canCancelSchedule: false,
            paymentDueStatus,
            depositDisabled,
            isAmexDisabled: paypalCommerce && paypalCommerce.amexEnabled === 'false',
            isPreview,
            pageName,
            payorSegmentation: req.discovery && req.discovery.payorSegmentation,
          };

          appContext.initialState.auth = {
            countryCode,
            regionIsoCode,
            ticket,
            authLevel,
            realmId,
            recipientEmail,
            isUserSignedIn,
            isSalesCheckoutInvoice,
            isQBDTInvoiceRollBack,
            authToken,
            isEntityPromoted,
            entityId,
            syncToken,
            username,
            userAgent: (headers && headers['user-agent']) || '',
          };

          appContext.initialState.ixp = ixp;

          if (appContext.initialState.companyInfo) {
            appContext.initialState.companyInfo.paypalMerchantId = paypalMerchantId;
          }

          let userAgent;
          if (isServer() && req.headers && req.headers['user-agent']) {
            userAgent = req.headers['user-agent'].toLowerCase();
          } else if (!isServer()) {
            userAgent = navigator.userAgent.toLowerCase();
          }

          const enabledPaymentOptions = getEnabledPaymentMethod(req.enabledPaymentMethods);
          if (req.isPayable && req.enabledPaymentMethods) {
            const { enabledPaymentMethods, companyInfo: { region } = {} } = req;
            let initialSelectedPaymentMethod = getInitialSelectedPaymentMethod({
              enabledPaymentMethods,
              enabledPaymentOptions,
              userAgent,
              isUserSignedIn,
              featureFlags,
              isPartiallyPaid,
              userWallets: [],
              saleType: sale && sale.type,
              ixp,
              url: `${req.protocol}://${req.host}${req.url}`,
            });
            appContext.initialState.insight.canBeFullyPaid =
              PaymentAmountValidator.getCanBeFullyPaid(
                balanceAmount,
                req.enabledPaymentMethods,
                region,
                featureFlags
              );
            const maxPaymentAmount = PaymentAmountValidator.getMaxPaymentAmount(
              balanceAmount,
              initialSelectedPaymentMethod,
              allowOverPay,
              region,
              featureFlags
            );
            let paymentData;
            if (paypalPayment && paypalPayment.paymentStatus === 'error') {
              const { paymentStatus, paymentError, paymentMethodType } = paypalPayment;
              paymentData = {
                paymentStatus,
                paymentError,
                paymentMethodType,
              };
            } else {
              paymentData = {
                paymentStatus: null,
                paymentError: null,
                paymentMethodType: initialSelectedPaymentMethod,
              };
            }
            const isACHOnlineFeeEnabled = req?.discovery?.sale?.convenienceFee?.ach?.enabled;
            const feeAmount = req?.discovery?.sale?.convenienceFee?.ach?.feeAmount;
            const paymentAmount =
              isACHOnlineFeeEnabled && feeAmount
                ? balanceAmount + parseFloat(feeAmount)
                : Math.min(balanceAmount, maxPaymentAmount);
            const amountData = {
              amount: paymentAmount,
              balanceAmount: balanceAmount,
              paymentMethod: initialSelectedPaymentMethod,
              allowOverPay: allowOverPay,
              transactionType: transactionType,
              gratuityValue: 0,
              region,
              featureFlags,
            };

            appContext.initialState.payment = {
              balanceAmount: balanceAmount,
              inputAmount: paymentAmount,
              defaultInputAmount: paymentAmount,
              gratuityValue: 0,
              amount: paymentAmount,
              isAmountValid: PaymentAmountValidator.validate(amountData),
              allowOverPay,
              partialPaymentEnabled: feeAmount !== undefined ? false : partialPaymentEnabled,
              requestIdInProgress: null,
              paymentSessionCounter: 0,
              isPaymentSessionDirty: false,
              successfulPaymentCounter: 0,
              payPalProcessor,
              lastSuccessfulPaymentResponse: null,
              paidInvoices: null,
              isPayPalCommerceInvoice,
              isSavePaymentMethodChecked: !isSubscription && isUserSignedIn,
              country: req.discovery && req.discovery.countryData,
              ...paymentData,
            };

            if (
              sale &&
              (isOneToManyPaymentRequest(sale.type, sale.subType) ||
                isMAIPasPDF({ sale, featureFlags }))
            ) {
              appContext.initialState.payment = {
                ...appContext.initialState.payment,
                isContactDetailsNameValid: true,
                isContactDetailsEmailValid: true,
              };
            }

            if (openInvoices) {
              appContext.initialState.invoice = {
                ...appContext.initialState.invoice,
                openInvoices: openInvoices.compatibleOpenInvoices.map((openInvoice) => ({
                  invoiceNumber: openInvoice.invoiceNumber,
                  dueDate: openInvoice.dueDate,
                  id: openInvoice.id,
                  totalAmt: openInvoice.totalAmt,
                  balance: openInvoice.balance,
                  selected: true,
                })),
              };
              appContext.initialState.payment = {
                ...appContext.initialState.payment,
                amount: maxPaymentAmount,
              };
            }
            appContext.initialState.wallet = {
              fetchWalletStatus: null,
              createWalletStatus: null,
              updateWalletStatus: null,
              deleteWalletStatus: null,
              userWallets: null,
              selectedWalletId: null,
              currentlyUpdatingWalletId: null,
              currentlyDeletingWalletId: null,
              walletError: null,
              selectedCardCvv: '',
              enabledPaymentMethods: req.enabledPaymentMethods,
              enabledCCTypes,
              isDetectingPayPalStatus:
                isPayPalCommerceInvoice ||
                req.enabledPaymentMethods.indexOf('paypal_ppaam') > -1 ||
                req.enabledPaymentMethods.indexOf('venmo') > -1,
              isDetectingApplePayStatus:
                req.enabledPaymentMethods.indexOf('cc') > -1 &&
                isAppleProduct(userAgent) &&
                isSafari(userAgent),
            };

            const isPaypalWalletDisabled =
              paypalCommerce && paypalCommerce.paypalWalletEnabled === 'false';
            if (
              req.enabledPaymentMethods &&
              req.enabledPaymentMethods.includes('paypal') &&
              isPaypalWalletDisabled
            ) {
              appContext.initialState.wallet.enabledPaymentMethods =
                req.enabledPaymentMethods.filter((pm) => pm !== 'paypal');
            }
            // handle enabled payment methods for bank paymentds (nanopay)
            appContext.initialState.wallet.enabledPaymentMethods =
              enabledPaymentMethodsForCABankPayments(
                req.enabledPaymentMethods,
                req.config,
                true,
                isPartiallyPaid,
                sale && sale.amount
              );
          }
        }
      }

      let { pageMessages, pageProps } = await Page.getInitialProps(appContext);

      return {
        pageProps,
        pageMessages,
        initialState: appContext.initialState,
      };
    }

    getIUS_SESSIONCookie = () =>
      document &&
      document.cookie.slice(
        document.cookie.indexOf('ius_session'),
        document.cookie.indexOf(';', document.cookie.indexOf('ius_session'))
      );

    /**
     * There are a few implementations here:
     * @param {{dispatch: import('redux-thunk').ThunkDispatch, featureFlags: Record<string, boolean>}} param0
     */
    startRiskAction = async ({ dispatch }) => {
      dispatch(getRiskProfileToken());
    };

    /**
     * @param {{dispatch: import('redux-thunk').ThunkDispatch, featureFlags: Record<string, any>, pageName: string, error: Error, companyLocale: string, onDocumentStateCompleteHandler: Function }} param0
     */
    startInitializationActions = async ({
      dispatch,
      pageName,
      error,
      featureFlags,
      companyLocale,
      onDocumentStateCompleteHandler,
    }) => {
      onDocumentStateCompleteHandler({
        companyLocale,
        pageName,
        usedCache: featureFlags['ssr-payload-cache'],
      });
      loadGdpr(this.reduxStore.getState());
      mark('startRiskAction');
      this.startRiskAction({ dispatch });
      dispatch(updateShouldShowSchedulePay());
      dispatch(updateShouldShowAutopay());
      dispatch(updateCanCancelSchedule());
      dispatch(checkIFPaypalEnabled());
      await dispatch(initializePPAAMPayLater());
      dispatch(getOpenInvoices());
      await dispatch(initializeApplePay());
      await dispatch(initializePayPal());
      dispatch(initPayorHistoryState());

      const { config, auth, companyInfo, insight, sale, ixp } = this.reduxStore.getState();
      SegmentIO.init({ ...config, ...auth, ...companyInfo, ...insight, ...sale, ixp });
      await SegmentIO.initTaxonomyV2(this.reduxStore.getState());
      dispatch(reportPageView({ pageName, error }));
    };

    componentDidMount() {
      const { dispatch } = this.reduxStore;
      dispatch(registerPageLoadObserver());
      if (!isServer()) {
        try {
          const {
            companyInfo,
            featureFlags = { ...featureFlags },
            error = {},
            ixp,
          } = this.reduxStore.getState();
          const companyLocale = companyInfoSelectors.localeSelector(companyInfo);
          const { pageName = 'n/a' } = this.props;
          const { dispatch } = this.reduxStore;
          this.startInitializationActions({
            dispatch,
            error,
            pageName,
            ixp,
            featureFlags,
            companyLocale,
            onDocumentStateCompleteHandler: onDocumentStateComplete,
          });
        } catch (e) {
          splunkReporter.contextual({
            logInfo: { logLevel: 'error', logger: 'apps/CPApp' },
            event: 'view',
            action: 'render',
            activityInfo: {
              status: 'error',
            },
            error: {
              stack: e.stack,
              message: e.message,
              where: 'componentDidMount',
            },
          });
        }
      }
    }

    render() {
      const { pageProps = {} } = this.props;

      return <Page {...pageProps} />;
    }
  };
};
export const CPStore = _CPStore;

const _CPError = (Page) => {
  return class PageWithCPError extends React.Component {
    static PAGE_MESSAGES = Page.PAGE_MESSAGES;
    constructor(props) {
      super(props);
      this.reduxStore = getOrCreateStore(props.initialReduxState);

      if (!isServer()) {
        // configure Logger and reporters
        Logger.addNamespace(new SplunkClientReporter(this.reduxStore));
        initSentry(props.initialReduxState);
        initLogger(props.initialReduxState);
      }
    }
    // eslint-disable-next-line no-unused-vars
    static async getInitialProps(appContext) {
      // fill initial state according to page
      const initialState = {};
      let errorObj = {};
      if (isServer()) {
        const {
          ctx: { req },
        } = appContext;
        if (req) {
          const { error, cookies } = req;
          if (error) {
            const { token } = error;
            errorObj = error;
            const ivid = cookies ? cookies.ivid : null;
            const invoice = {
              isPayable: req.isPayable && req.enabledPaymentMethods,
              token,
              ivid,
            };
            initialState.invoice = invoice;
          }
        }
      }

      let { pageMessages, pageProps } = await Page.getInitialProps(appContext);
      pageProps.error = errorObj;

      return {
        pageProps,
        pageMessages,
        initialState,
      };
    }
    componentDidMount() {
      try {
        // since this action is optional, we don't need to crash the page if it fails
        const { dispatch } = this.reduxStore;
        dispatch(reportPageView({ pageName: 'error', error: this.props.error }));
      } catch (error) {
        // do nothing since logging may be forbidden
      }
    }

    render() {
      const { pageProps = {} } = this.props;

      return <Page {...pageProps} />;
    }
  };
};
export const CPError = _CPError;

const performanceMark = () => ({
  __html: `if (typeof performance === 'object' && typeof performance.now === 'function') {
      window.SSR_VISIBLE = performance.now();
    }`,
});
const initSegmentIO = (segmentToken) => ({
  __html: `try {
    var analytics = window.analytics = window.analytics || [];
    if (!analytics.initialize) {
        if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");
        else {
            analytics.invoked = !0;
            analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"];
            analytics.factory = function(t) {
                return function() {
                    var e = Array.prototype.slice.call(arguments);
                    e.unshift(t);
                    analytics.push(e);
                    return analytics
                }
            };
            for (var t = 0; t < analytics.methods.length; t++) {
                var e = analytics.methods[t];
                analytics[e] = analytics.factory(e)
            }
            analytics.load = function(t, e) {
                try {
                    var n = document.createElement("script");
                    n.type = "text/javascript";
                    n.async = !0;
                    n.src = "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";
                    n.onerror = (error) => {
                        window.analyticsError = error
                    }
                    var a = document.getElementsByTagName("script")[0];
                    a.parentNode.insertBefore(n, a);
                    analytics._loadOptions = e
                } catch (error) {
                    window.analyticsError = error
                }
            };
            analytics.SNIPPET_VERSION = "4.1.0";
            analytics.load("${segmentToken}");
            analytics.page();
        }
    };
} catch (e) {
    window.analyticsError = e
}`,
});
const catchAllError = () => ({
  __html: `
window.onerror = function (message, source, lineno, colno, error) {
  function callSSRErrorEndpoint() {
    try {
      let headers = {
        'Content-Type': 'application/json',
      };
      let endpoint = window.location.href.replace('view', 'clientError');

      let xhr = new XMLHttpRequest();
      xhr.open('POST', endpoint);

      xhr.withCredentials = true;
      Object.keys(headers).map(function (header) {
        xhr.setRequestHeader(header, headers[header]);
      });
      xhr.send(
        JSON.stringify({
          EventName: 'Uncaught Error',
          EventType: 'Global Error Catcher',
          message: message,
          source: source,
          lineNumber: lineno,
          colNumber: colno,
          error: error,
          stack: error && error.stack,
          ErrorMessage: error && error.message,
        })
      );
    } catch (e) {
      console.log(e);
    }
  }

  try {
    if (typeof window.__NEXT_DATA__ === 'object') {
      let state = __NEXT_DATA__.props.initialReduxState;
      let featureFlags = state.featureFlags;
      let config = state.config;
      let invoice = state.invoice;
      let auth = state.auth;
      let wallet = state.wallet;
      let endpoint = '/' + config.portal + '/rest/reporting/batch';
      let ssrtid = config.ssrtid;

      let headers = {
        'Content-Type': 'application/json',
        'Intuit-DomainId': invoice.domainId,
        'Intuit-IntuitId': auth.entityId,
        'Intuit-RealmId': auth.realmId,
        'Intuit-ACSToken': invoice.token,
        sessionTicket: auth.ticket,
        'user-signed-in': auth.isUserSignedIn,
        syncToken: auth.syncToken,
        'ssr-session-id': ssrtid,
      };
      let xhr = new XMLHttpRequest();
      xhr.open('POST', endpoint);
      xhr.withCredentials = true;
      Object.keys(headers).map(function (header) {
        xhr.setRequestHeader(header, headers[header]);
      });
      xhr.send(
        JSON.stringify({
          messages: [
            {
              data: {
                EventName: 'Uncaught Error',
                EventType: 'Global Error Catcher',
                message: message,
                source: source,
                lineNumber: lineno,
                colNumber: colno,
                error: error,
                stack: error && error.stack,
                ErrorMessage: error && error.message,
                endpoint: endpoint,
                ssrtid: ssrtid,
                token: invoice.token,
                companyLocale: invoice.companyLocale,
                view2pay: invoice.view2pay,
                isDetectingApplePayStatus: wallet.isDetectingApplePayStatus,
                fetchWalletStatus: wallet.fetchWalletStatus,
                isUserSignedIn: auth.isUserSignedIn,
              },
              opts: {
                logLevel: 3,
                logType: 'error',
                userLogLevel: 3,
              },
            },
          ],
          ssrtid: ssrtid,
        })
      );
    } else {
      callSSRErrorEndpoint();
    }
  } catch (e) {
    callSSRErrorEndpoint();
  }
}
  `,
});

const _Document = (props) => {
  const {
    req: {
      // new-di-risk-library to remove riskJS, also remove from cpclient-config
      config: { endpoints: { cdn, riskJS } = {}, ppaam: { clientId } = {}, segmentToken },
      featureFlags,
      enabledPaymentMethods = [],
      isPayPalCommerceInvoice,
      discovery,
    },
  } = props.context;
  const isPaypalPPAAMEnabled = enabledPaymentMethods.includes('paypal_ppaam');
  let paypalSDKUrl = 'https://www.paypalobjects.com/api/checkout.js';
  let ppcDRModule = 'https://www.paypalobjects.com/api/checkout.js';
  let ppcBNCode = '';
  let ppcToken = '';
  if (discovery && isPayPalCommerceInvoice) {
    const { paypalCommerce } = discovery.externalPaymentMethods;
    const { tokenPPC, payPalURL, payPalDRMModule, payPalBNCode } = paypalCommerce;
    ppcToken = tokenPPC;
    paypalSDKUrl = payPalURL;
    ppcDRModule = payPalDRMModule;
    ppcBNCode = payPalBNCode;
  }

  const loadPaypal = enabledPaymentMethods && enabledPaymentMethods.indexOf('paypal') > -1;

  return (
    <Html>
      <Head>
        <title key="title">Intuit QuickBooks</title>
        <script key="catch-all" dangerouslySetInnerHTML={catchAllError()} />
        {isPaypalPPAAMEnabled && (
          <script
            src={`https://www.paypal.com/sdk/js?client-id=${clientId}&components=buttons,funding-eligibility&enable-funding=venmo`}
          />
        )}
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0"
        />
        <meta key="robots" name="robots" content="noindex,nofollow" />
        <SocialMeta featureFlags={featureFlags} discovery={discovery} cdn={cdn} />
        <link
          key="favicon"
          rel="apple-touch-icon icon shortcut"
          type="image/png"
          href={`${cdn}/favicon.png`}
        />
      </Head>
      <body className="custom_class">
        <Main />
        {<script dangerouslySetInnerHTML={performanceMark()} />}
        {<script defer={true} dangerouslySetInnerHTML={initSegmentIO(segmentToken)} />}
        {
          // This needs to load sync so that the button can be inserted into the page before the page is fully loaded
          loadPaypal && (
            <script
              type="text/javascript"
              data-requirecontext="_"
              data-requiremodule={ppcDRModule}
              src={paypalSDKUrl}
              data-client-token={ppcToken}
              data-partner-attribution-id={ppcBNCode}
            />
          )
        }
        {featureFlags['new-di-risk-library'] ? undefined : (
          <script type="text/javascript" src={riskJS} />
        )}
        <NextScript />
      </body>
    </Html>
  );
};

export const Document = _Document;
